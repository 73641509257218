import React from 'react';

const ProductProtection = ({ protection }) => {
  const getIcons = () => {
    const vars = protection.split('_');

    return vars[0].split('+').map((elem, index) => {
      switch (elem) {
        case 'S1P':
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/puntera-${
                  vars[1]
                    ? vars[1].indexOf('PUM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/plantilla-${
                  vars[1]
                    ? vars[1].indexOf('PLM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
            </li>
          );
        case 'SBP':
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/puntera-${
                  vars[1]
                    ? vars[1].indexOf('PUM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/plantilla-${
                  vars[1]
                    ? vars[1].indexOf('PLM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/sbp.png`}
              />
            </li>
          );
        case 'S3':
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/puntera-${
                  vars[1]
                    ? vars[1].indexOf('PUM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/plantilla-${
                  vars[1]
                    ? vars[1].indexOf('PLM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/s3.png`}
              />
            </li>
          );
        case 'S2':
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/puntera-${
                  vars[1]
                    ? vars[1].indexOf('PUM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/s2.png`}
              />
            </li>
          );
        case 'S1':
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/puntera-${
                  vars[1]
                    ? vars[1].indexOf('PUM') === -1
                      ? 'azul'
                      : 'gris'
                    : 'azul'
                }.png`}
              />
              <img
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/s1.png`}
              />
            </li>
          );
        case 'ESD':
          return <></>;
        default:
          return (
            <li key={`proteccion-${elem}-${index}`}>
              <img
                // data-image={`proteccion-${elem}`}
                // type="radio"
                id={`proteccion-${elem}`}
                alt={`proteccion ${elem}`}
                src={`/normativa/${elem.toLowerCase()}.png`}
                // onClick={handleClick}
              />
            </li>
          );
      }
    });
  };

  return <ul class="protection-list">{getIcons()}</ul>;
};

export default ProductProtection;
