import { Link } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ProductColors from './productColors';
import {
  getColorName,
  imageFallback,
  getProductURL,
  getProtectionList,
} from '../utils';
import classnames from 'classnames';
import ProductProtection from './productProtection';

const ProductDetail = ({ product }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [category, setCategory] = useState('');

  const baseImgUrl = product && getProductURL('detail', product);

  useEffect(() => {
    if (product) {
      const categoryLine = product.category.replaceAll('-', ' ');
      setCategory(categoryLine);

      const color = product.colors ? product?.colors.split('/')[0] : null;
      setSelectedColor(color);
    }
  }, [product]);

  const handleClick = (e) => {
    const color = !!e.currentTarget.value ? e.currentTarget.value : 0;
    setSelectedColor(color);
  };

  const imgClass = classnames('active', {
    'mendi-product': product?.marca === 'mendi',
  });

  const getImgVelilla = () =>
    product?.colors
      .split('/')
      .filter((item) => item === selectedColor)
      .map((elem) => {
        let parsedColor = elem;
        if (elem.indexOf('-') !== -1){
          const [first, second] = elem.split('-');
          parsedColor = first.length === 1 ? `0${first}-${second}` : `${first}-${second}`;
          const [first2, second2] = parsedColor.split('-');
          parsedColor = second2.length === 1 ? `${first2}-0${second2}` : `${first2}-${second2}`;
        } else {
          parsedColor = elem.length === 1 ? `0${elem}` : elem;
        }
        const imgSrc = `${parsedColor.replace('-','_')}.jpg`;
        
        return (
          <img
            data-image={`color-${elem}`}
            key={`color-${elem}`}
            className={imgClass}
            src={`${baseImgUrl}${imgSrc}`}
            alt="imagen de producto"
            onError={imageFallback}
          />
        );
      });
  const getImgMendi = () => (
    <img
      className={imgClass}
      src={`${baseImgUrl}`}
      alt="imagen de producto"
      onError={imageFallback}
    />
  );

  const getImg = () =>
    product.marca === 'mendi' ? getImgMendi() : getImgVelilla();

  const getSizes = () => {
    return product?.tallas
      .trim()
      .split('/')
      .map((e) => e.trim())
      .join(' / ');
  };

  return product ? (
    <main className="container">
      <div className="left-column">{product && getImg()}</div>

      <div className="right-column">
        <div className="product-description">
          <Link
            to={`/listado/${product.category}`}
            className="product-description__category-link"
          >
            <span>{`< Volver a ${category}`}</span>
          </Link>
          <p className="product-ref">
            Ref. {product?.ref}{' '}
            {product.linea && (
              <span className="product-line">
                {' '}
                - Línea. {product?.linea.toUpperCase()}
              </span>
            )}{' '}
          </p>
          <h1>
            {product?.name.charAt(0).toUpperCase() +
              (product.marca === 'velilla'
                ? product?.name.slice(1)
                : product?.name.slice(1).toUpperCase())}
          </h1>
          {product.description && <p>{product.description}</p>}
        </div>
        <div className="product-configuration">
          {product.pvp && 
            <div className="product-feature product-feature__price-container">
              <span className="product-feature__price">
                {`${product.pvp} € / unidad(es)`}
              </span>
              <span className="product-feature__price-vat">
                {'IVA no incluido'}
              </span>
            </div>
          }
          {product.colors && (
            <div className="product-feature">
              <span className="product-feature__title">
                Colores disponibles
              </span>

              <ProductColors
                colors={product?.colors}
                handleClick={handleClick}
              />
              <span className="product-feature__color-name">
                {getColorName(selectedColor)}
              </span>
            </div>
          )}
          <div className="product-feature product-size">
            <span className="product-feature__title">
              Tallas elegibles{' '}
              <span className="product-sizes__link">
                (<Link to="/guia-de-tallas">Guía de tallas</Link>)
              </span>
            </span>

            <div className="size-choose">{getSizes()}</div>
          </div>
          {product.proteccion && (
            <span>
              <b>Protección:</b>
              <span className="product-sizes__link">
                (<Link to="/normativa-calzado">Normativa</Link>)
              </span>
              <br></br>
              {getProtectionList(product)}
              <ProductProtection protection={product.proteccion} />
            </span>
          )}
        </div>
      </div>
    </main>
  ) : (
    <h1>Producto no encontrado</h1>
  );
};

export default ProductDetail;
