import colorCatalog from '../data/colors.json';

export const getColorName = (color) => {
  if (color && color.indexOf('-') !== -1) {
    return `(${color.split('-')[0]}-${color.split('-')[1]}) ${
      colorCatalog[color.split('-')[0]]
    } / ${colorCatalog[color.split('-')[1]]}`;
  }
  return color && `(${color}) ${colorCatalog[color]}`;
};

export const imageFallback = (ev) => {
  ev.target.src = '/imagen-no-disponible.png';
};

export const getProductURL = (type, product) => {
  return product.marca === 'velilla'
    ? getVelillaProductURL(type, product)
    : getMendiProductURL(type, product);
};
export const getVelillaProductURL = (type, product) => {
  let pages = product?.ref.length === 1 ? [product.ref.toLowerCase(),'_'] : [product?.ref[0].toLowerCase(), product?.ref[1].toLowerCase()];

  return type === 'list'
    ? `/images/list/${product?.ref.replaceAll('-', '').replaceAll('_','').toLowerCase()}.jpg`
    : `/images/product/${product?.ref.replaceAll('-', '').replaceAll('_','').toLowerCase()}-`;
};
export const getMendiProductURL = (type, product) => {
  return type === 'list'
    ? `https://grupomendi.com/${
        product?.var
      }-home_default/${product?.ref.replaceAll(' ', '-')}.jpg`
    : `https://grupomendi.com/${
        product?.var
      }-large_default/${product?.ref.replaceAll(' ', '-')}.jpg`;
};

export const getProtectionList = (product) => {
  const firstPart = product.proteccion.split('_')[0];
  return firstPart.split('+').join(' + ');
};
