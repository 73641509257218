import React from 'react';
import { Link } from '@reach/router';

const Catalog = () => {
  return (
    <>
      <h1 className="categories__title">Listado de categorias de productos</h1>
      <ul className="categories">
        <Link
          as="li"
          to={`/listado/alta-visibilidad-y-prendas-tecnicas`}
          className="categories__item"
        >
          <span>{'Alta Visibilidad y Prendas Técnicas'}</span>
        </Link>
        <Link
          as="li"
          to={`/listado/industria-base`}
          className="categories__item"
        >
          <span>{'Industria Base'}</span>
        </Link>
        <Link
          as="li"
          to={`/listado/industria-alimentaria`}
          className="categories__item"
        >
          <span>{'Industria Alimentaria'}</span>
        </Link>
        <Link
          as="li"
          to={`/listado/sanidad-y-limpieza`}
          className="categories__item"
        >
          <span>{'Sanidad y Limpieza'}</span>
        </Link>
        <Link as="li" to={`/listado/hosteleria`} className="categories__item">
          <span>{'Hostelería'}</span>
        </Link>
        <Link as="li" to={`/listado/desechables`} className="categories__item">
          <span>{'Desechables'}</span>
        </Link>
        <Link
          as="li"
          to={`/listado/calzado-y-guantes`}
          className="categories__item"
        >
          <span>{'Calzado y Guantes'}</span>
        </Link>
      </ul>
      <h2 className="categories__title">Guía de tallas</h2>
      <ul className="categories">
        <Link as="li" to={`/guia-de-tallas`} className="categories__item">
          <span>{'Consulta nuestra guía de tallas para productos'}</span>
        </Link>
        <Link as="li" to={`/normativa-calzado`} className="categories__item">
          <span>{'Consulta la normativa sobre calzado'}</span>
        </Link>
      </ul>
    </>
  );
};

export default Catalog;
