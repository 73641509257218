import React, { useEffect, useState } from 'react';

const Searcher = ({ onChange }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    onChange(search);
  }, [search, onChange]);

  return (
    <div className="searcher">
      <input
        className="searcher__input"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        placeholder="Busca aqui tu producto por nombre, referencia..."
      />
      <span className="searcher__icon">
        <img src="/search.png" alt="buscar" />
      </span>
    </div>
  );
};

export default Searcher;
