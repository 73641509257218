import { Link } from '@reach/router';
import React from 'react';
// import ProductColors from './productColors';
import { imageFallback, getProductURL, getProtectionList } from '../utils';

const ProductItemMendi = ({ product }) => {
  const url = getProductURL('list', product);
  const getSizes = () =>
    product?.tallas
      .trim()
      .split('/')
      .map((e) => e.trim())
      .join(' / ');

  return (
    <div>
      <Link to={`/producto/${product?.ref}`} className="product-item">
        <div className="product-item__image-container">
          <img
            className="product-item__image"
            src={url}
            alt={product?.name}
            onError={imageFallback}
          />
        </div>
        <span className="product-item__ref">Ref. {product?.ref}</span>
        {product.linea && (
          <span className="product-item__linea">Línea. {product?.linea}</span>
        )}
        <span className="product-item__name">
          <b>
            {product?.name.charAt(0).toUpperCase() + product?.name.slice(1)}
          </b>
        </span>
        {product.proteccion && (
          <span>
            <b>Protección:</b>
            <br></br>
            {getProtectionList(product)}
          </span>
        )}
        <span className="product-item__sizes">{getSizes()}</span>
      </Link>
    </div>
  );
};

export default ProductItemMendi;
