import React from "react";
import classNames from "classnames";
import { getColorName } from "../utils";

const ProductColors = ({ colors, handleClick, small }) => {
  const classColors = classNames("color-choose", {
    "color-choose--small": small,
  });

  const getColors = () =>
    colors.split("/").map((elem, index) => (
      <div key={`color-${elem}-${index}`}>
        <input
          data-image={`color-${elem}`}
          type="radio"
          id={`color-${elem}`}
          name={`color-${elem}`}
          value={elem}
          onClick={handleClick}
        />
        <label htmlFor={`color-${elem}`}>
          <span className={`color-${elem}`} style={{backgroundImage:"url('/colors/" + elem + ".png')"}} title={getColorName(elem)}></span>
        </label>
      </div>
    ));

  return <div className={classColors}>{getColors()}</div>;
};

export default ProductColors;
