import React from "react";
import withGoogleSheets from "react-db-google-sheets/dist/withGoogleSheets";
import ProductDetail from "../components/productDetail";

const sheet = "Hoja 1";
const ProductPage = ({ productId, db }) => {
  const product = db[sheet].find((product) => product.ref === productId);
  return (
    <section className="product-page">
      <ProductDetail product={product} />
    </section>
  );
};

export default withGoogleSheets(sheet)(ProductPage);
