import React from "react";

const Sizes = () => {
  return (
    <div className="sizes">
      <h1 className="sizes__header">Tallaje</h1>
      <p className="sizes__text">
        El cliente puede utilizar estas tablas para confirmar que talla
        necesita. Las medidas que aportamos en las tablas son anatómicas,
        tomadas del cuerpo desnudo. Cuando se fabrica una prenda, debemos
        agregar una holgura para conseguir comodidad. Dicha holgura varía según
        el diseño, tejido, sexo, etc. convirtiéndola en una prenda más o menos
        entallada.
      </p>
      <p>
        <span>*</span> Recuerde que para tomar estas medidas correctamente,
        tendrá que medir el contorno completo
      </p>
      <img className="sizes__image" src="/tallaje1.jpg" alt="guia de tallas" />
      <p className="sizes__title">Guia de tallas Hombre</p>
      <img
        className="sizes__image"
        src="/tallaje2.jpg"
        alt="guia de tallas hombre"
      />
      <p className="sizes__title">Guia de tallas Mujer</p>
      <img
        className="sizes__image"
        src="/tallaje3.jpg"
        alt="guia de tallas mujer"
      />
      <p className="sizes__title">Guia de tallas Unisex</p>
      <img
        className="sizes__image"
        src="/tallaje4.jpg"
        alt="guia de tallas unisex"
      />
      {/* <p className="sizes__name">{product?.name}</p>
        <p className="sizes__sizes">{product?.tallas}</p> */}
    </div>
  );
};

export default Sizes;
