import React, { useState, useRef, useEffect } from 'react';
import ProductItem from './productItem';
import { withGoogleSheets } from 'react-db-google-sheets';
import { Link } from '@reach/router';
import categoryList from '../data/categories.json';
import ProductItemMendi from './productItemMendi';
import Searcher from './Sercher';

const sheet = 'Hoja 1';

const ProductList = ({ db, categoryId }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [search, setSearch] = useState('');
  const header = useRef(null);
  const handleScroll = () => {
    if (header.current) {
      setIsSticky(header.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', () => handleScroll);
  }, []);

  const parsedCat = categoryList[categoryId]
    ? categoryList[categoryId]
    : categoryId.replaceAll('-', ' ');
  const searching = (text) => {
    setSearch(text.toLowerCase());
  };
  const isSearched = (elem) => {
    if (!search) {
      return true;
    }

    return (
      (elem.ref && elem.ref.toLowerCase().indexOf(search) !== -1) ||
      (elem.name && elem.name.toLowerCase().indexOf(search) !== -1) ||
      (elem.protection &&
        elem.protection.toLowerCase().indexOf(search) !== -1) ||
      (elem.linea && elem.linea.toLowerCase().indexOf(search) !== -1)
    );
  };
  const list = db[sheet]
    .filter(
      (elem) =>
        elem.category === categoryId && elem.activo === '1' && isSearched(elem)
    )
    .map((elem) => {
      switch (elem.marca) {
        case 'mendi':
          return <ProductItemMendi key={elem.ref} product={elem} />;
        default:
          return <ProductItem key={elem.ref} product={elem} />;
      }
    });

  return (
    <div className="product-list-container">
      <div
        className={`product-list__header sticky-wrapper${
          isSticky ? ' sticky' : ''
        }`}
        ref={header}
      >
        <Link to="/" className="product-list__link">
          <span>{`< Volver a Categorias`}</span>
        </Link>
        <h1>Listado de productos de {parsedCat}</h1>
        <Searcher onChange={searching} />
        <h3>{`${list.length} productos`}</h3>
      </div>
      <ul className="product-list">{list}</ul>
      <Link to="/" className="product-list__link">
        <span>{`< Volver a Categorias`}</span>
      </Link>
    </div>
  );
};

export default withGoogleSheets(sheet)(ProductList);
