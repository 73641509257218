import React from 'react';

const Normativa = () => {
  return (
    <div className="sizes">
      <h1 className="sizes__header">Normativa</h1>
      <div className="leyenda-container row">
        <div className="leyenda-col-1 col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="iso-leyenda">
            <p className="iso-title">Calzado de seguridad en ISO 20345:2011</p>

            <div className="leyenda-sbp leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/sbp.png" alt="SBP" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/puntera-azulgris.png"
                  alt="Puntera Azul/Gris"
                />
                <img
                  src="/normativa/plantilla-azulgris.png"
                  alt="Plantilla Azul/Gris"
                />
              </div>
            </div>
            <div className="leyenda-s1 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/s1.png" alt="S1" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/puntera-azulgris.png"
                  alt="Puntera Azul/Gris"
                />
              </div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/fo.png" alt="Fo" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
              </div>
            </div>
            <div className="leyenda-s1p leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/s1p.png" alt="S1P" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/puntera-azulgris.png"
                  alt="Puntera Azul/Gris"
                />
                <img
                  src="/normativa/plantilla-azulgris.png"
                  alt="Plantilla Azul/Gris"
                />
              </div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/fo.png" alt="Fo" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
              </div>
            </div>
            <div className="leyenda-s2 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/s2.png" alt="S2" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/puntera-azulgris.png"
                  alt="Puntera Azul/Gris"
                />
              </div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/fo.png" alt="Fo" />
                <img src="/normativa/wru.png" alt="Wru" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
              </div>
            </div>
            <div className="leyenda-s3 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/s3.png" alt="S3" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/puntera-azulgris.png"
                  alt="Puntera Azul/Gris"
                />
                <img
                  src="/normativa/plantilla-azulgris.png"
                  alt="Plantilla Azul/Gris"
                />
              </div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/fo.png" alt="Fo" />
                <img src="/normativa/wru.png" alt="Wru" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
                <p>Suela con tacos</p>
              </div>
            </div>
          </div>

          <div className="iso-leyenda">
            <p className="iso-title">Calzado de trabajo en ISO 20347:2012</p>

            <div className="leyenda-01 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/01.png" alt="01" />
              </div>
              <div className="puntera-plantilla-inner"></div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
              </div>
            </div>
            <div className="leyenda-02 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/02.png" alt="02" />
              </div>
              <div className="puntera-plantilla-inner"></div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/wru.png" alt="Wru" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
              </div>
            </div>
            <div className="leyenda-03 leyenda-norma">
              <div className="normativa-inner">
                <img src="/normativa/03.png" alt="03" />
              </div>
              <div className="puntera-plantilla-inner">
                <img
                  src="/normativa/plantilla-azulgris.png"
                  alt="Plantilla Azul/Gris"
                />
              </div>
              <div className="pictograma-inner">
                <img src="/normativa/a.png" alt="A" />
                <img src="/normativa/e.png" alt="E" />
                <img src="/normativa/wru.png" alt="Wru" />
              </div>
              <div className="iso-norma-txt">
                <p>Trasera cerrada</p>
                <p>Suela con tacos</p>
              </div>
            </div>
          </div>
        </div>

        <div className="leyenda-col-2 col-xs-12 col-sm-12 col-md-12 col-lg-8">
          <p className="definiciones-title">Definiciones</p>

          <div className="definiciones-punteras-plantillas">
            <div className="definiciones-punteras">
              <p className="definicion-planpun-title">
                Puntera de protección 200 julios
              </p>
              <div className="definicion-planpun-inner">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/puntera-azul.png" alt="Puntera Azul" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>No metálica</p>
                </div>
              </div>
              <div className="definicion-planpun-inner">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/puntera-gris.png" alt="Puntera Gris" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Metálica</p>
                </div>
              </div>
            </div>

            <div className="definiciones-plantillas">
              <p className="definicion-planpun-title">
                Plantilla antiperformación
              </p>
              <div className="definicion-planpun-inner">
                <div className="definicion-pictograma-img">
                  <img
                    src="/normativa/plantilla-azul.png"
                    alt="Plantilla Azul"
                  />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Textil composite alta</p>
                  <p>Tenacidad 0 penetraciíon</p>
                </div>
              </div>
              <div className="definicion-planpun-inner">
                <div className="definicion-pictograma-img">
                  <img
                    src="/normativa/plantilla-gris.png"
                    alt="Plantilla Gris"
                  />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Acero inoxidable</p>
                </div>
              </div>
            </div>
          </div>

          <div className="definiciones-pictogramas">
            <div className="definiciones-pictograma-inner">
              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/a.png" alt="A" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Calzado antiestático</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/e.png" alt="E" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Capacidad de absorción de energía en el talón</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/ci.png" alt="Ci" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Suela aislante contra el frío</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/hi.png" alt="Hi" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Suela aislante contra el calor</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/hro.png" alt="Hro" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Resistencia de la suela al calor por contacto</p>
                </div>
              </div>
            </div>

            <div className="definiciones-pictograma-inner">
              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/an.png" alt="An" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Protección de los tobillos</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/fo.png" alt="Fo" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Suela resistente a hidrocarburos</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/wr.png" alt="Wr" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Calzado resistente al agua</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/wru.png" alt="Wru" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Corte resistente a la penetración y absorción de agua</p>
                </div>
              </div>
            </div>

            <div className="definiciones-pictograma-inner">
              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/sra.png" alt="SRA" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Cerámica +</p>
                  <p>Solución detergente</p>
                  <p>Plano: ≥ 0,32</p>
                  <p>Tacón: ≥ 0,28</p>
                </div>
              </div>

              <div className="definicion-pictograma">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/srb.png" alt="SRB" />
                </div>
                <div className="definicion-pictograma-txt">
                  <p>Acero +</p>
                  <p>Glicerina</p>
                  <p>Plano: ≥ 0,18</p>
                  <p>Tacón: ≥ 0,13</p>
                </div>
              </div>

              <div className="definicion-pictograma definicion-src">
                <div className="definicion-pictograma-img">
                  <img src="/normativa/src.png" alt="SRC" />
                  <img src="/normativa/equal.png" alt="Igual a" />
                  <img src="/normativa/sra.png" alt="SRA" />
                  <img src="/normativa/plus.png" alt="Más" />
                  <img src="/normativa/src.png" alt="SRB" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Normativa;
