import { Link } from '@reach/router';
import React from 'react';
import ProductColors from './productColors';
import { imageFallback, getProductURL, getProtectionList } from '../utils';

const ProductItem = ({ product }) => {
  const url = getProductURL('list', product);

  const getSizes = () =>
    product?.tallas
      .trim()
      .split('/')
      .map((e) => e.trim())
      .join(' / ');

  return (
    <div>
      <Link to={`/producto/${product?.ref}`} className="product-item">
        <img
          className="product-item__image"
          src={url}
          alt={product?.name}
          onError={imageFallback}
        />
        <span className="product-item__ref">Ref. {product?.ref}</span>
        <span className="product-item__name">
          {product?.name.charAt(0).toUpperCase() + product?.name.slice(1)}
        </span>
        {product.proteccion && (
          <span>
            <b>Protección:</b>
            <br></br>
            {getProtectionList(product)}
          </span>
        )}
        {product.colors && <ProductColors colors={product?.colors} small />}
        <span className="product-item__sizes">{getSizes()}</span>
      </Link>
    </div>
  );
};

export default ProductItem;
