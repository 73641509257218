import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__col-left">
        <img src="/logo-arco-web.png" alt="logo arco" />
      </div>
      <div className="footer__col-right">
        <p className="footer__title">Dirección:</p>
        <p>Fuente Perinda, 4 Bajo</p>
        <p>49800 Toro (Zamora)</p>
        <p className="footer__title">Teléfonos:</p>
        <p className="footer__phones">
          <span>980 691 983</span> <span>610 732 191</span>{' '}
          <span>635 526 496</span>
        </p>
        <p className="footer__title">Email:</p>
        <p>publiarco.toro@gmail.com</p>
      </div>
    </footer>
  );
};

export default Footer;
