import { Router } from '@reach/router';
import React from 'react';
import './App.scss';
import Footer from './components/footer';
import Header from './components/header';
import ProductList from './components/productList';
import ProductPage from './pages/ProductPage';
import GoogleSheetsProvider from 'react-db-google-sheets';
import Catalog from './components/catalog';
import Sizes from './components/sizes';
import Normativa from './components/normativa';

function App() {
  return (
    <GoogleSheetsProvider>
      <div className="App">
        <Header />
        <section className="main-section">
          <Router basepath={'/'} className="checkout__content">
            <Catalog path="/" />
            <ProductList path="listado/:categoryId" />
            <ProductPage path="producto/:productId" />
            <Sizes path="guia-de-tallas" />
            <Normativa path="normativa-calzado" />
          </Router>
        </section>
        <Footer />
      </div>
    </GoogleSheetsProvider>
  );
}

export default App;
