import React from "react";

const Header = () => {
  return (
    <header className="header">
      <img src="/favicon.png" alt="logo arco" />
      Catálogo de producto 2020/21 - Publiarco
    </header>
  );
};

export default Header;
